<template>
  <div class="detail-card bda-4 my-16 pa-16" v-if="dataDetail.driverShippingBillGoodsVO">
    <div class="d-flex mb-20">
      <div class="circle text-center">
        <Icon name="hygl" />
      </div>
      <span class="ml-8">单号：{{ dataDetail.shippingBillNumber }}</span>
    </div>
    <div class="d-flex font-14 justify-space-between mb-20">
      <span class="text_muted detail-card-title">路线名称</span>
      <span class="detail-card-content text-right">{{ dataDetail.routeName }}</span>
    </div>
    <div class="d-flex font-14 justify-space-between mb-12">
      <span class="text_muted">货物名称</span>
      <span>{{ dataDetail.driverShippingBillGoodsVO.goodsName }}</span>
    </div>
    <div class="d-flex justify-space-between">
      <div class="flex-1 text-center">
        <div class="d-flex flex-column">
          <div class="text-center  mb-8">
            <Icon name="dj" />
          </div>
          <div class="lh-12">
            <span class="font-16 fw-700 mr-4">{{ dataDetail.pricePlanDTO.transportPrice / 100 }}</span>
            <span class="text_muted font-12">{{ dataDetail.pricePlanDTO.priceUnitTypeStr }}</span>
          </div>
        </div>
      </div>
      <div class="flex-1 text-center">
        <div class="d-flex flex-column">
          <div class="text-center mb-8">
            <Icon name="dczh" />
          </div>
          <div class="lh-12">
            <span class="font-16 fw-700 mr-4">
              {{
                (dataDetail.publishType === 0
                  ? useWeightDeal(
                      dataDetail.driverShippingBillGoodsVO.weightUnit,
                      dataDetail.driverShippingBillGoodsVO.actualWeight
                    )
                  : useWeightDeal(dataDetail.driverShippingBillGoodsVO.weightUnit, dataDetail.weightLimit) ||
                    dataDetail.volumeLimit / 1000
                ).toFixed(2)
              }}
            </span>
            <span class="text_muted font-12">{{ dataDetail.driverShippingBillGoodsVO.weightUnitStr }}/车</span>
          </div>
        </div>
      </div>
      <div class="flex-1 text-center">
        <div class="d-flex flex-column">
          <div class="text-center mb-8">
            <Icon name="hl" />
          </div>
          <div class="lh-12">
            <span class="font-12 text_muted">剩余</span>
            <span class="font-16 fw-700">
              {{
                useWeightDeal(
                  dataDetail.driverShippingBillGoodsVO.weightUnit,
                  dataDetail.driverShippingBillGoodsVO.actualWeight
                ).toFixed(2)
              }}
            </span>
            <span class="font-12 fw-700">{{ dataDetail.driverShippingBillGoodsVO.weightUnitStr }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from '@vue/composition-api';
import useWeightDeal from '@hook/useWeightDeal';
export default defineComponent({
  name: 'DetailCard',
  props: {
    dataDetail: {
      type: Object,
      default: () => {}
    }
  },
  setup() {
    return {
      useWeightDeal
    };
  }
});
</script>
<style lang="less"></style>
