import { defineStore } from 'pinia';
import { useUserStore } from '@/store/user';
import {
  apiSearchCreateBillCarrierPayee,
  apiSearchCreateBillPayee,
  apiCheckVirtualAccountSuccess,
  apiCheckCarrierVirtualAccountSuccess
} from '@/api/mine';
import { apiGetSourceDetail, apiTakingOrder } from '@/api/source';

import { Toast } from 'vant';

export const useSourceStore = defineStore({
  id: 'source-detail',
  state: () => ({
    perList: [], // 个人银行卡
    carList: [], // 车队长银行卡
    sourceDetail: {}, // 货源详情
    selectCarrier: {},
    selectTruck: {
      carLicensePlate: ''
    },
    isVirtualAccount: false // 是否创建虚拟账户
  }),
  actions: {
    updateInfo(val) {
      this.selectCarrier = val;
    },
    updateSelectTruck(val) {
      this.selectTruck = val;
    },
    async queryCarrierList() {
      const userInfo = useUserStore();
      try {
        const carRes = await apiSearchCreateBillCarrierPayee({
          // 先拉取车队长的银行卡列表(车队长可能为空)
          driverId: userInfo.driverId,
          idCard: userInfo.idCard,
          taxSourceId: this.sourceDetail.taxSourceId,
          companyId: this.sourceDetail.companyId
        });
        this.carList = carRes.data || [];
        const perRes = await apiSearchCreateBillPayee({
          driverId: userInfo.driverId,
          idCard: userInfo.idCard,
          taxSourceId: this.sourceDetail.taxSourceId,
          companyId: this.sourceDetail.companyId
        });
        this.perList = perRes.data || [];
      } catch (err) {
        console.log(err);
        Toast.fail(err);
      }
    },
    async querySourceDetail(params) {
      let res;
      try {
        res = await apiGetSourceDetail(params);
        const { data } = res;
        if (data) {
          this.sourceDetail = data;
        }
      } catch (err) {
        console.log(err);
        res = err;
        Toast.fail(err);
      }
      return res;
    },
    async queryCheckVirtualAccount(taxSourceId) {
      // 判断用户是否可以接单操作
      const userInfo = useUserStore();
      try {
        const carRes = await apiCheckCarrierVirtualAccountSuccess({
          driverId: userInfo.driverId,
          idCard: userInfo.idCard,
          taxSourceId
        });
        const res = await apiCheckVirtualAccountSuccess({
          driverId: userInfo.driverId,
          idCard: userInfo.idCard,
          taxSourceId
        });
        this.isVirtualAccount = carRes.data || res.data;
      } catch (err) {
        Toast.fail(err);
      }
    },
    async queryTakingOrder(params, resolve, reject) {
      const userInfo = useUserStore();
      try {
        Toast.loading('正在接单中...');
        await apiTakingOrder({
          ...params,
          driverId: userInfo.driverId
        });
        Toast.success('接单成功!');
        typeof resolve === 'function' && resolve();
      } catch (err) {
        typeof reject === 'function' && reject();
      }
    }
  }
});
