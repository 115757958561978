<template>
  <div class="routes-card bda-4 pa-16" v-if="dataDetail.addressInfoDTO">
    <div class="d-flex mb-16">
      <div class="circle text-center mr-8">
        <Icon name="gs" />
      </div>
      <span>{{ dataDetail.shippingBillShipperInfoDTO.shipperName }}</span>
    </div>
    <div class="bdt py-16">
      <div class="d-flex align-center" v-if="dataDetail.addressInfoDTO.receiverDistance !== 0">
        <div class="small-circle mr-4 bg-primary"></div>
        <span class="text_muted">全程约</span>
        <span class="lh-24 ml-6">{{ (dataDetail.addressInfoDTO.receiverDistance / 1000).toFixed(2) }}km</span>
      </div>
      <div class="d-flex align-center" v-if="dataDetail.addressInfoDTO.receiverDistance !== 0">
        <div class="small-circle mr-4  bg-warning"></div>
        <span class="text_muted">距我</span>
        <span class="lh-24 ml-6">{{ (dataDetail.addressInfoDTO.senderDistance / 1000).toFixed(2) }}km</span>
      </div>
    </div>
    <div class="pt-16">
      <div class="d-flex justify-space-between mb-8">
        <div class="d-flex">
          <div class="text_warning text-center circle mr-8">
            发
          </div>
          <span class="fw-700">{{ dataDetail.addressInfoDTO.senderName }}</span>
        </div>
        <div class="d-flex text_primary">
          <span class="mr-8">{{ dataDetail.addressInfoDTO.senderPhone }}</span>
          <a :href="`tel:${dataDetail.addressInfoDTO.senderPhone}`"><Icon name="dh"/></a>
        </div>
      </div>
      <div class="d-flex text_secondary flex-column send-address pl-20 ml-12 pb-24 mb-12">
        <div>
          {{ dataDetail.addressInfoDTO.senderProvinceName }}
          {{ dataDetail.addressInfoDTO.senderDistrictName }}
          {{ dataDetail.addressInfoDTO.senderCityName }}
        </div>
        <div>{{ dataDetail.addressInfoDTO.senderLocation }}</div>
      </div>
      <div class="d-flex justify-space-between">
        <div class="d-flex">
          <div class="text_success text-center circle mr-8">
            收
          </div>
          <span class="fw-700">{{ dataDetail.addressInfoDTO.receiverName }}</span>
        </div>
        <div class="d-flex text_primary">
          <span class="mr-8">{{ dataDetail.addressInfoDTO.receiverPhone }}</span>
          <a :href="`tel:${dataDetail.addressInfoDTO.receiverPhone}`"><Icon name="dh"/></a>
        </div>
      </div>
      <div class="text_secondary d-flex flex-column receiver-address pl-20 ml-12 pb-24">
        <div>
          {{ dataDetail.addressInfoDTO.receiverProvinceName }}
          {{ dataDetail.addressInfoDTO.receiverDistrictName }}
          {{ dataDetail.addressInfoDTO.receiverCityName }}
        </div>
        <div>{{ dataDetail.addressInfoDTO.receiverLocation }}</div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from '@vue/composition-api';
export default defineComponent({
  name: 'RouteCard',
  props: {
    dataDetail: {
      type: Object,
      default: () => {}
    }
  }
});
</script>
<style lang="less"></style>
